


import { FaTools } from "react-icons/fa";
import "./Airflow.css";

function Airflow() {
    return (
        <div className="airflow-construct">
            <FaTools size={40}></FaTools>
            <p>Under Construction</p>
        </div>
    )
}

export default Airflow;
