import GeneralModal from '../GeneralModal/GeneralModal';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactFlow, { ReactFlowProvider,
    addEdge, 
    applyEdgeChanges,
    applyNodeChanges,
    Controls,
    Background
}  from 'reactflow';
import 'reactflow/dist/style.css';
import OperatorNode from '../OperatorNode/OperatorNode';
import { getOperatorMetadata } from '../../utils/General';
import { createElementId } from '../../utils/General';
import { saveWorkflowCall, initWorkflowCall } from '../../utils/Workflow';
import { useAuth } from '../../context/AuthProvider';
import Modal from 'react-bootstrap/Modal';
import Alerts from '../Alerts/Alerts';
import TopBarTools from '../TopBarTools/TopBarTools';
import uninovalogo from "./uninova.jpg"
import "./Workspace.css"

// Node id initialiation and incrementation
let id = 0;
const getId = () => `dndnode_${id++}`;

const nodeTypes = {Operator : OperatorNode};

function Workspace({ value, loadWF , setLoadWF}){

    const { user } = useAuth();

    const [wf, setWf] = useState({id: createElementId(value), name: value, desc: '', owner: user.email, default_args: [], args: [], tasks: []});
    // React Flow specific states
    // TODO
    const reactFlowWrapper = useRef(null);
    // State to control the nodes that appear on the screen
    const [nodes, setNodes] = useState([]);
    // State to control the connections between nodes
    const [edges, setEdges] = useState([]);
    // TODO
    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );

    // Function to be called when the user wants to do a connection between 2 nodes
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );
     const onConnect = useCallback(
        (connection) => setEdges((eds) => addEdge(connection, eds)),
        [setEdges]
    );
    
    // Function to handle a drag of the node from the side bar
    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const [showModal, setShowModal] = useState(false);

    const [showAlertSaveWorflow, setShowAlertSaveWorflow] = useState(false);
    const [showAlertInitWorkflow, setShowAlertInitWorkflow] = useState(false);
    const [alertProps, setAlertProps] = useState({});

    const deleteNodeById = (id) => {
        reactFlowInstance.setNodes((nds) => nds.filter((node) => node.id !== id))
    }

    // Function to handle a drop of a node into the canvas and to "print" that node
    const onDrop = useCallback(
        async (event) => {
            setShowModal(true);
            event.preventDefault();
            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');

            var opData = await getOperatorMetadata(type);
            delete opData._id;
            // console.log("Op data", opDdata);

            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });

            let curr_id = getId();
            const newNode = {
                id: curr_id,
                type: "Operator",
                position,
                data: {
                    name: opData.op_pres_name,
                    id: curr_id,
                    type: type,
                    info: opData,
                    onDelete: {deleteNodeById}
                },
                hidden : true
            };
            setNodes((nds) => nds.concat(newNode));  
        },
        [reactFlowInstance]
    );

    // console.log("Nodes fora", nodes);
    
    const saveWorkflow = () => {
        let copyWF = {...wf};
        copyWF.tasks = [];
        // console.log("Nodes", nodes);
        
        // nodes.forEach(node => {
        //     node.data.info.dependencies = [];
        //     let found_dependencies = [];

        //     // Improve find
        //     found_dependencies = edges.filter(edge => edge.target == node.id);
        //     if (found_dependencies){
        //         found_dependencies.forEach(element => {
        //             let found = nodes.find(n => n.id == element.source);
        //             node.data.info.dependencies.push(found.data.name);
        //             // console.log("Depend", found.data.name);
        //         });
        //     }
        //     copyWF.tasks.push(node.data.info)
        // });
        copyWF.tasks = [reactFlowInstance.toObject()];

        setWf(copyWF);
        // console.log("Edges", edges);
        // console.log("Instance", copyWF);
        console.log("WF", copyWF);

        // API call to save workflow 
        let response = saveWorkflowCall(copyWF);
        // console.log("Response", response);
        setAlertProps({
            type: "success",
            header: "Successfully Saved",
            description: "Workflow Successfully Saved!"
        });
        setShowAlertSaveWorflow(true);
    }

    const initWorkflow = () => {
        let copyWF = {...wf};
        copyWF.tasks = [];
        
        nodes.forEach(node => {
            node.data.info.dependencies = [];
            let found_dependencies = [];

            // Improve find
            found_dependencies = edges.filter(edge => edge.target == node.id);
            if (found_dependencies){
                found_dependencies.forEach(element => {
                    let found = nodes.find(n => n.id == element.source);
                    node.data.info.dependencies.push(found.data.name);
                    // console.log("Depend", found.data.name);
                });
            }
            copyWF.tasks.push(node.data.info)
        });

        setWf(copyWF);
        // console.log("Nodes", nodes);
        // console.log("Edges", edges);
        // console.log("WF", wf);

        // API call to save workflow 
        let response = initWorkflowCall(copyWF, user.email);
        // console.log("Response", response);
        setAlertProps({
            type: "success",
            header: "Successfully Started",
            description: "Workflow Started Successfully!"
        });
        setShowAlertInitWorkflow(true);
    }

    // const testeLoadFlow = useCallback( (flow) => {
    //     setNodes(flow.nodes || []);
    //     setEdges(flow.edges || []);
    // }, [reactFlowInstance]);

    const aTentar = useCallback( () => {
        const aTentar2 = async () => {
            if(loadWF.is){
                // testeLoadFlow(loadWF.data.tasks[0]);
                setNodes(loadWF.data.tasks[0].nodes);
                setEdges(loadWF.data.tasks[0].edges);
                setLoadWF({"is": false});

                // console.log("Será?");
                // console.log("Instance", reactFlowInstance);
                var lastNodeId = loadWF.data.tasks[0].nodes[loadWF.data.tasks[0].nodes.length-1].id;
                var lastChar = lastNodeId.substr(lastNodeId.length - 1);
                id = parseInt(lastChar) + 1;
            }
        }

        aTentar2();

    }, [reactFlowInstance]);

    useEffect(() => {
        aTentar();
    }, [loadWF, reactFlowInstance]);

    // console.log("Instance", reactFlowInstance);

    // Add animation in edges
    const edgesWithUpdatedTypes = edges.map((edge) => {
        edge.animated = true;
        return edge;
    });


    return (
        <>
            <div className='workspace' ref={reactFlowWrapper}>
                <TopBarTools saveWorkflow={saveWorkflow} initWorkflow={initWorkflow}></TopBarTools>
                <ReactFlowProvider>
                    <ReactFlow 
                        nodes={nodes}
                        edges={edgesWithUpdatedTypes}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        onInit={setReactFlowInstance}
                        nodeTypes={nodeTypes} 
                        id={createElementId(value)}
                    >
                        <Background color="#8064a2" gap={20} />
                        <img id="uninova-logo" className="navbar-logo" src={uninovalogo} alt="uninovalogo" />
                        <Controls></Controls>
                    </ReactFlow>  
                </ReactFlowProvider>
                <GeneralModal 
                    nodes={nodes} 
                    setNodes={setNodes} 
                    type='Operator' 
                    show={showModal} 
                    close={() => setShowModal(false)}
                />
            </div>
            <Modal show={showAlertSaveWorflow} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Alerts alertProps={alertProps} close={() => setShowAlertSaveWorflow(false)}/>
            </Modal>
            <Modal show={showAlertInitWorkflow} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Alerts alertProps={alertProps} close={() => setShowAlertInitWorkflow(false)}/>
            </Modal>
        </>
    )
}

export default Workspace;