import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { checkWorkflowIdExists } from '../../utils/Workflow';
import { createElementId } from '../../utils/General';
import { wf_list } from '../../utils/Global';
import Alerts from '../Alerts/Alerts';
import { getWorkflowObj } from '../../utils/Workflow';
import ModalLists from './ModalLists';
import "./ModalLists.css"
 
function GeneralModal({ show, close , type, setModalType, panelTabs, setPanelTabs, setKey, nodes, setNodes, wfList, setLoadWF}) {
    
    const [formText, setFormText] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertProps, setAlertProps] = useState({});

    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log(formText);
        if(type == 'Workflow') {
            let check = await checkWorkflowIdExists(formText)
            // console.log("Check ", check);
            if(check){
                close();
                setAlertProps({
                    type: "danger",
                    header: "Workflow Error",
                    description: "A workflow with that name already exists in the database. Please select a different name."
                });
                setShowAlert(true);
            }else{
                let wf_id = createElementId(formText) ;
                var wf = {id: wf_id, name: formText, desc: '', default_args: [], args: [], tasks: []};
                wf_list.push(wf);
                const newElement = formText;
                setPanelTabs([...panelTabs, newElement]);
                setKey(newElement);
                close();
            }
        }else if(type == 'Operator'){
            // console.log("Nodes add", nodes);
            let nodesItems = [...nodes];
            let lastNode = {
                ...nodesItems[nodesItems.length -1],
            };
            lastNode.hidden = false;
            lastNode.data.name = createElementId(formText);
            lastNode.data.info["id"] = createElementId(formText) ;
            lastNode.data.info["unique_name"] = formText ;
            lastNode.data.info["dependencies"] = [] ;

            lastNode.data.info.op_params.op_kwargs.forEach((element, index) => {
                if ("default" in element){
                    lastNode.data.info.op_params.op_kwargs[index].value = element.default ;    
                } else {
                    lastNode.data.info.op_params.op_kwargs[index].value = undefined ;
                }
            });

            nodesItems[nodesItems.length -1] = lastNode;
            setNodes(nodesItems);

            close();
        }
        setFormText('');
    };

    const handleChange = (event) => {
        setFormText(event.target.value)
    };

    const handleClose = () => {
        if (type == "Operator") {
            let nodesItems = [...nodes];
            nodesItems.pop()
            setNodes(nodesItems);
        }
        setFormText('');
        close();
    };

    const handleWfSelect = async (wfObj) => {
        if (panelTabs.some(tab => tab === wfObj.name)) {
            // abrir alert a avisar que já existe aberta a tab
            //setModalType("Workflow already opened");
            setAlertProps({
                type: "info",
                header: "Workflow already loaded",
                description: "This workflow is already opened in a tab!"
            });
            setShowAlert(true);
        } else {
            // call API 
            let data = await getWorkflowObj(wfObj.id);
            // add tab
            setPanelTabs([...panelTabs, wfObj.name]);
            setKey(wfObj.name);
            // build nodes & edges from json obj
            // console.log("Data", data);
            setLoadWF({"is": true, "data": data});
        }
    }
    
    return (
        <div>
            <style type="text/css">
                {`
                .btn-i4q {
                    background-color: #8064a2;
                    color: white;
                }
                `}
            </style>
            { type === "Operator" || type === "Workflow" ?
                <Modal show={show} onHide={close} size="s" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>New {type}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label>{type} Name</Form.Label>
                                <Form.Control autoFocus type='' value={formText} onChange={handleChange}/>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="i4q" className='modal-button' onClick={handleSubmit}>
                            Create
                        </Button>
                    </Modal.Footer>
                </Modal>
                :
                <Modal show={show} onHide={close} size="s" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Workflow List</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ModalLists handleWfSelect={handleWfSelect} wfList={wfList} close={close} ></ModalLists>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            <Modal show={showAlert} aria-labelledby="contained-modal-title-vcenter" centered>
                <Alerts alertProps={alertProps} close={() => setShowAlert(false)}/>
            </Modal>
        </div>
       
    );
}

export default GeneralModal;