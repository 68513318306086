// React Imports
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PerfilMenu from '../components/ProfileMenu/ProfileMenu';
import 'reactflow/dist/style.css';
// Custom Imports
import SideBar from '../components/SideBar/SideBar';
import Start from '../components/Start/Start.jsx';
import Airflow from '../components/Airflow/Aiflow';
import Workspace from '../components/Workspace/Workspace';
import IntegrateOperator from '../components/IntegrateOperator/IntegrateOperator';
import { VscClose } from "react-icons/vsc";
import { deleteWorkflowFromList } from '../utils/Workflow'

import 'reactflow/dist/style.css';


function MainPage() {
    // State with tabs list for workflows and setter function
    const [panelTabs, setPanelTabs] = useState([]);
    // State with keys to control logic between tab selection
    const [key, setKey] = useState('Start');

    // Control Start Tab
    const [startTab, setStartTab] = useState(false);
    
    const [loadWF, setLoadWF] = useState({"is": false});
    const [showOpTab, setShowOpTab] = useState(false);
    
    const removeTab = (tabToRemove) => {
        const updatedPanelTabs = panelTabs.filter((tab) => {
          return tab !== tabToRemove;
        });
      
        setPanelTabs(updatedPanelTabs);
        setStartTab(!startTab);
    };
    
    const closeTab = (value) => {
        // console.log("valor", value);
        deleteWorkflowFromList(value);
        removeTab(value);
    }

    useEffect(() => {
        setKey('Start');
    }, [startTab]);

    const hideTab = () => {
        setShowOpTab(false);
        setStartTab(!startTab);
    }   

    const openShowOpTab = () => {
        setShowOpTab(true);
        setKey('New Operator');
    }


    return (
        <Container fluid className='container-h'>
                <Row>
                    <Col xs={2} className="p-0">
                        <SideBar activeKey={key}/>
                    </Col>
                    <Col xs={10} className="p-0 canvas-column">
                        <PerfilMenu></PerfilMenu>
                        <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                            <Tab className='tab-style' eventKey='Start' title="Start">
                                <Start showOpTab={showOpTab} openShowOpTab={openShowOpTab} setLoadWF={setLoadWF} panelTabs={panelTabs} setPanelTabs={setPanelTabs} setKey={setKey}/>
                            </Tab>
                            {panelTabs.map((value) => {
                                return(
                                    <Tab
                                        className='tab-style' 
                                        key={value} 
                                        eventKey={value} 
                                        title={
                                            <div>
                                                {value.charAt(0).toUpperCase() + value.slice(1)} 
                                                <VscClose onClick={() => closeTab(value)} className='close-tab-icon' size={17}></VscClose>
                                            </div>
                                        }
                                    >
                                        <Workspace setLoadWF={setLoadWF} loadWF={loadWF} value={value}></Workspace>
                                    </Tab>
                                );
                            })}
                            { showOpTab && 
                                <Tab 
                                    className='tab-style' 
                                        eventKey='New Operator' 
                                        title={
                                            <div>
                                                New Operator
                                                <VscClose onClick={hideTab} className='close-tab-icon' size={17} />
                                            </div>}>
                                    <IntegrateOperator hideTab={hideTab} />
                                </Tab> 
                                }
                            {/* <Tab className='tab-style' eventKey='Airflow' title='Airflow'>
                                <Airflow></Airflow>
                            </Tab>  */}
                        </Tabs>
                    </Col>
                </Row>               
            </Container>
    );
}

export default MainPage;