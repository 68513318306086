import { useRouteError } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Image } from 'react-bootstrap';

import i4qlogo from "./i4Q logo.png";
import uninovalogo from "./uninova.png";
import vesselailogo from "./vesselai-logo-simple.png"


export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div className='bg-gradient-primary'>
            <Container className='login-container'>
                <Row className="justify-content-center">
                    <Col xl={5} lg={12} md={9} className='column-style'>
                        <Card className="o-hidden border-0 shadow-lg my-5">
                            <Card.Body className="p-0">
                                <div className='login-header'>
                                    <Image className='p-0 i4qlogo' fluid id='i4qlogo' src={i4qlogo} alt="i4qlogo" />
                                </div>
                                <div id="error-page" className="p-5 login-box error-page">
                                    <h1>Oops!</h1>
                                    <p>Sorry, an unexpected error has occurred.</p>
                                    <p>
                                        {error.status} - {error.statusText || error.message}
                                    </p>
                                </div>
                                
                            </Card.Body>
                            <Image className='uninova-logo' src={uninovalogo} alt="uninovalogo" />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}