import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import { useState } from "react";
import { integrateNewOperator } from "../../utils/Operator";
import Alerts from "../Alerts/Alerts";
import { Modal } from "react-bootstrap";

function IntegrateOperator({ hideTab }) {

    const [newOpForm, setNewOpForm] = useState({
        opName: '',
        config: '',
    });

    const [fileForm, setFileForm] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const [alertProps, setAlertProps] = useState({});

    const handleChange = (event) => {
        setNewOpForm({ ...newOpForm, [event.target.name]: event.target.value });
    }

    const handleChangeFile = (event) => {
        // console.log(event.target.files);
        setFileForm(event.target.files);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let response = await integrateNewOperator(newOpForm, fileForm[0]);
        // console.log("New Op form", newOpForm);
        // console.log("File Form", fileForm[0]);
        // console.log("Response", response);

        if(response.url === "/"){
            setAlertProps({
                type: "info",
                header: "Success",
                description: "New operator successfully integrated!"
            });
            setShowAlert(true);
        }
    }

    const handleClose = () => {
        setShowAlert(false);
        hideTab();
    }

    if(showAlert){
        return(
            <Modal show={showAlert} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Alerts 
                    alertProps={alertProps} 
                    close={handleClose}/>
            </Modal>
        );
    }

    return (
        <Container className="mt-5">
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>1. New Operator Name</Form.Label>
                    <div>
                        <Form.Text className="text-muted">
                            Name:
                        </Form.Text>
                    </div>
                    <Form.Control name="opName" value={newOpForm.opName} onChange={handleChange} type="text"/>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>2. Operator Script</Form.Label>
                    <Form.Control type="file" onChange={handleChangeFile}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>3. Operator Configuration JSON</Form.Label>
                    <div>
                        <Form.Text className="text-muted">
                            Configuration JSON:
                        </Form.Text>
                    </div>
                    <Form.Control
                        name="config" 
                        value={newOpForm.config} 
                        onChange={handleChange} 
                        as="textarea"
                        style={{ height: '300px' }}
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
                <Button variant="danger" type="submit">
                    Cancel
                </Button>
            </Form>
        </Container>
    );
}

export default IntegrateOperator;