// React Imports
import React from 'react';
import LoginPage from './pages/LoginPage';
import ErrorPage from './pages/ErrorPage';
import RegisterPage from './pages/RegisterPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import MainPage from './pages/MainPage';
// CSS Imports
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import './App.css'


import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { AuthProvider } from './context/AuthProvider';

function App() {

    const router = createBrowserRouter([
        {
            path: "/",
            element: 
                <AuthProvider>
                    <LoginPage />
                </AuthProvider>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/register",
            element: <RegisterPage />
        },
        {
            path: "/forgot_password",
            element: <ForgotPasswordPage />
        },
        {
            path: "/reset_password",
            element: <ResetPasswordPage />
        },
        {
            path:"/da",
            element:
                <AuthProvider>
                    <ProtectedRoute>
                        <MainPage />
                    </ProtectedRoute>   
                </AuthProvider> 
        }
    ]);

    return (
        <>
            <RouterProvider router={router} />
        </>
    );
}

export default App;