import Dropdown from 'react-bootstrap/Dropdown';
import { FaUser } from "react-icons/fa";
import { useAuth } from '../../context/AuthProvider';
import { IoSettingsOutline } from "react-icons/io5";
import { MdLogout, MdFormatListBulleted, MdSettings } from "react-icons/md";
import './ProfileMenu.css'

function ProfileMenu() {

  const { user, logout } = useAuth();

  const handleClickLogout = () => {
    logout();
  }

  return (
    <div className='profile-menu'>
      <Dropdown>
        <Dropdown.Toggle variant="none" id="dropdown-basic">
          <div className='user-menu'>
            <FaUser className='user-logo' size={20}></FaUser>
            <div className='user-email'>{user.email}</div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="#/action-1" disabled={"disabled"}><FaUser size={14} className="icon-menu-profile icon-profile" ></FaUser>Profile</Dropdown.Item>
          <Dropdown.Item href="#/action-2" disabled={"disabled"}><MdSettings size={16} className="icon-menu-profile"></MdSettings> Settings</Dropdown.Item>
          <Dropdown.Item href="#/action-3" disabled={"disabled"}><MdFormatListBulleted size={16} className="icon-menu-profile"></MdFormatListBulleted> Activity Log</Dropdown.Item>
          <Dropdown.Item onClick={handleClickLogout}><MdLogout size={16} className="icon-menu-profile"></MdLogout> Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default ProfileMenu;