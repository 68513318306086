import Accordion from 'react-bootstrap/Accordion';
import { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaSearch } from "react-icons/fa";
import i4qlogobranco from "./logo branco1.webp"
import { FaDatabase, FaMicrochip } from "react-icons/fa";
import { IoConstruct } from "react-icons/io5";
import { TiFlowChildren } from "react-icons/ti";
import { apiHost, apiPort } from '../../utils/Global';
import "./SideBar.css"

const apiConnectionString = `${apiHost}:${apiPort}`;

function SideBar({ activeKey }) {

    const [operators, setOperators] = useState({});

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Drag to Workspace
        </Tooltip>
    );

    async function getOperatorsList() {
        var response = await fetch(apiConnectionString + "/get_operators_list")
        // var response = await fetch("http://localhost:5002/get_operators_list");
        // console.log("Response ", response);
        if (response) {
            var data = await response.json();
            // console.log("Data ", data);
            setOperators(data)
        }
    }
    // console.log("Operators ", operators);

    useEffect(() => {
        getOperatorsList();
    }, []);

    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <>
            <div className='header-logo-sidebar'>
                <div className='logo-i4q-white'>
                    <img id="project-logo" className="navbar-logo-i4q" src={i4qlogobranco} alt="i4qlogobranco" />
                    <div className='text-logo'>Industrial Data Services for Quality Control in Smart Manufacturing</div>
                </div>
                {/* <div id="linha-horizontal"></div> */}
            </div>
            <div className="sidebar-style">
                { activeKey !== "Start" && activeKey !== "New Operator" ?
                    <>
                        <div className='menu'>
                            <TiFlowChildren className='logo-operator-menu' size={22}/>
                            <p className="operator-menu">Menu Operators</p>
                        </div>
                        <InputGroup className="search-menu">
                            <Form.Control
                                type="search"
                                placeholder="Search operators"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                disabled
                            />
                            <InputGroup.Text id="basic-addon1" className='search-sidebar' disabled={"disabled"}><FaSearch className='search-button-sidebar'></FaSearch></InputGroup.Text>
                        </InputGroup>
                        <Accordion className="p-2" flush alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><FaDatabase className="icon-sidebar"></FaDatabase> Connectors</Accordion.Header>
                                <Accordion.Body style={{"backgroundColor":"#8064a2"}}>
                                    {
                                        Object.keys(operators).length !== 0 && 
                                        Object.values(operators.connectors).map((value, index) => {
                                            return(
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 100, hide: 0 }}
                                                    overlay={renderTooltip}
                                                    key={value.op_name + index}
                                                >
                                                    <Card 
                                                        key={value.op_name + index}
                                                        className="mb-1"
                                                        onDragStart={(event) => onDragStart(event, value.op_name)} draggable
                                                    >
                                                        <Card.Body>
                                                            {/* <p>{value.visibility} connector</p> */}
                                                            {value.op_pres_name}
                                                        </Card.Body>
                                                    </Card>  
                                                </OverlayTrigger>
                                            );
                                        }
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header ><IoConstruct className="icon-sidebar"></IoConstruct> Transformers</Accordion.Header>
                                <Accordion.Body style={{"backgroundColor":"#8064a2"}}>
                                    {
                                        Object.keys(operators).length !== 0 && 
                                        Object.values(operators.transformers).map((value, index) => {
                                            return(
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 100, hide: 0 }}
                                                    overlay={renderTooltip}
                                                    key={value.op_name + index}
                                                >
                                                    <Card 
                                                        key={value.op_name + index}
                                                        className="m-1"
                                                        onDragStart={(event) => onDragStart(event, value.op_name)} draggable
                                                    >
                                                        <Card.Body>
                                                            {value.op_pres_name}
                                                        </Card.Body>
                                                    </Card>       
                                                </OverlayTrigger>
                                            );
                                        }
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><FaMicrochip className="icon-sidebar"></FaMicrochip> Analytics</Accordion.Header>
                                <Accordion.Body style={{"backgroundColor":"#8064a2"}}>
                                    {
                                        Object.keys(operators).length !== 0 && 
                                        Object.values(operators.analytics).map((value, index) => {
                                            return(
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 100, hide: 0 }}
                                                    overlay={renderTooltip}
                                                    key={value.op_name + index}
                                                >
                                                    <Card 
                                                        key={value.op_name + index}
                                                        className="m-1"
                                                        onDragStart={(event) => onDragStart(event, value.op_name)} draggable
                                                    >
                                                        <Card.Body>
                                                            {value.op_pres_name}
                                                        </Card.Body>
                                                    </Card>  
                                                </OverlayTrigger>
                                            );
                                        }
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                    :
                    <></>
                }
            </div>
            
            <div className='uninova-2023'>
                {/* <div id="linha-horizontal-bottom"></div> */}
                <p className='uninova-2023-text'>UNINOVA | Copyright© 2023 - All rights reserved</p>
            </div>
        </>

    );
}

export default SideBar;