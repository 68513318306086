import { apiHost, apiPort } from "./Global";


const apiConnectionString = `${apiHost}:${apiPort}`;


function createElementId(name) {
    let id = name.toLowerCase();
    id = id.replace(/ /g, "-");
    // console.log("General", name);
    return id;
}

const getOperatorMetadata = async (type) => {
    const formData = new FormData();
    formData.append('name', type);
    var response = await fetch(apiConnectionString + "/get_operator_metadata", {
        'method': 'POST',
        'body' : formData
    });
    if (response) {
        var data = await response.json();
        // console.log("Data2 ", data);
        return data;
    }
}


export {createElementId, getOperatorMetadata}