import { useState } from 'react';
import { Handle, Position } from 'reactflow';
import SideBarOperator from '../SideBar/SideBarOperator';
import { FaDatabase, FaMicrochip } from "react-icons/fa";
import { BsFillGearFill } from "react-icons/bs";
import { IoClose, IoConstruct } from "react-icons/io5";
import { CgFileDocument } from "react-icons/cg";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './OperatorNode.css'


function OperatorNode( {data} ) {
    // console.log("data operatorNode", data);

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);

    const renderTooltipGear = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Operator Settings
        </Tooltip>
    );

    const renderTooltipClose = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Remove Operator
        </Tooltip>
    );

    const renderTooltipDoc = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Operator Documentation
        </Tooltip>
    );

    return (
        <div className={
            data.info.op_type == "connector" ?
            "operator-connector" :
            data.info.op_type == "transformer" ?
            "operator-transformer" :
            "operator-analytics"
        }>
            <div className='operator-buttons'>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 1 }}
                    overlay={renderTooltipClose}
                >
                    <div className={
                            data.info.op_type == "connector" ?
                            "close-style-connector" :
                            data.info.op_type == "transformer" ?
                            "close-style-transformer" :
                            "close-style-analytics"
                        }>
                                <IoClose onClick={() => data.onDelete.deleteNodeById(data.id)} style={{cursor: "pointer"}} size={25}></IoClose>   
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 1 }}
                    overlay={renderTooltipGear}
                    >
                    <div className={
                        data.info.op_type == "connector" ?
                        "gear-style-connector" :
                        data.info.op_type == "transformer" ?
                        "gear-style-transformer" :
                        "gear-style-analytics"
                    }>
                                <BsFillGearFill style={{cursor: "pointer"}} onClick={handleShow} size={16}></BsFillGearFill>                        
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 1 }}
                    overlay={renderTooltipDoc}
                >
                    <div className={
                            data.info.op_type == "connector" ?
                            "doc-style-connector" :
                            data.info.op_type == "transformer" ?
                            "doc-style-transformer" :
                            "doc-style-analytics"
                        }>
                                <CgFileDocument style={{cursor: "pointer"}} onClick={() => console.log("Open Documentation Node")} size={17}></CgFileDocument>                        
                    </div>
                </OverlayTrigger>
                <p style={
                    data.info.op_type == "connector" ?
                    {color: '#0069af'} :
                    data.info.op_type == "transformer" ?
                    {color: '#2b9348'} :
                    {color: '#A5243D'}
                    } className='text-xs'>{data.info.op_type}
                </p> 
            </div>
            <Handle
                type="target"
                position="left"
            />
            <div className='content-node'>
                <div className='flex-between'>
                    <div>
                        <div className={
                            data.info.op_type == "connector" ?
                            "text-op-pres-name-connector" :
                            data.info.op_type == "transformer" ?
                            "text-op-pres-name-transformer" :
                            "text-op-pres-name-analytics"
                        }>{data.info.op_pres_name}</div>
                        <p className='text-gray-800'>{data.name}</p>
                    </div>
                </div>
                <div className={
                        data.info.op_type == "connector" ?
                        "data-icon-connector" :
                        data.info.op_type == "transformer" ?
                        "data-icon-transformer" :
                        "data-icon-analytics"
                    }>
                    {
                        data.info.op_type == "connector" ?
                        <FaDatabase size={35}></FaDatabase> :
                        data.info.op_type == "transformer" ?
                        <IoConstruct size={35}></IoConstruct> :
                        <FaMicrochip size={35}></FaMicrochip>
                    }  
                </div>
            </div>
            <Handle
                type="source"
                position={Position.Right}
            />
            <SideBarOperator data={data} show={show} close={() => setShow(false)}></SideBarOperator>
      </div>
    )
};

export default OperatorNode;