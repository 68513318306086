import ListGroup from "react-bootstrap/ListGroup";

function ModalLists ({ wfList, close, handleWfSelect }) {

    const handleClick = (event) => {
        let wfObj = {};
        wfObj = wfList.find(wf => wf.id == event.target.value);
        handleWfSelect(wfObj);
        close();
    }

    // console.log("value", value);
    return(
        <ListGroup>
            {wfList.map((value, index) => {
                return (
                    <ListGroup.Item value={value.id} key={index} onClick={handleClick} action variant="light">
                        {value.name}
                    </ListGroup.Item>
                );        
            })}
        </ListGroup>
    );
}

export default ModalLists;