import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
import "./SideBar.css"

function SideBarOperator( { show, close, data }) {

    const [opParams, SetOpParams] = useState(data.info.op_params.op_kwargs)

    const handleSubmit = () => {
        // console.log("Clickei");
        
    }
    // console.log("Content", data);
    const handleChange = (event) => {

        let copyOpParams = [...opParams];

        let found = copyOpParams.find(element => element.name === event.target.name)

        // console.log("Name", event.target.name);
        // console.log("Value", event.target.value);
        // console.log("Copy", copyOpParams);
        // console.log("Found:", found);
        found.value = event.target.value;
        SetOpParams(copyOpParams);
    }

    return(
        <>
            <Offcanvas backdrop="static" placement="end" name="end" show={show} onHide={close} >
                <Offcanvas.Header >
                    <Offcanvas.Title>Edit {data.info.unique_name} Properties</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            {data.info.op_params.op_kwargs.map((value, index) => {
                                return(
                                    <div key={index}>
                                        <Form.Label >{value.pres_name}:</Form.Label>
                                        {value.type === 'enum_string' ? 
                                            <Form.Select defaultValue={opParams[index].value} name={value.name} onChange={handleChange}>
                                                {value.accepted_values.map((v, i) => {
                                                    return(<option key={i} value={v}>{v}</option>);
                                                })}
                                            </Form.Select>
                                            :
                                            <Form.Control autoFocus type='' value={opParams[index].value} name={value.name} onChange={handleChange}/>
                                        }
                                        <br></br>
                                    </div>
                                );
                            })}
                        </Form.Group>
                    </Form>
                </Offcanvas.Body>
                <Button className='offcanvas-button-save modal-button btn btn-i4q' variant="primary" onClick={close}>
                    Save
                </Button>
            </Offcanvas>
        </>
    );
}

export default SideBarOperator