import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import "./Alerts.css"

function Alerts({ show, close, alertProps}) {
  return (
    <>
      {/* {type === "Operator" || type === "Workflow" ?
        <Alert show={show} variant="danger" onClose={close} dismissible>
          <Alert.Heading>{type} already exists</Alert.Heading>
          <p>
            A workflow with that name already exists in the database. Please select a different name.
          </p>
        </Alert>
        :
        <Alert show={show} variant="info" onClose={close} dismissible>
          <Alert.Heading>Information</Alert.Heading>
          <p>
            {type}
          </p>
        </Alert>
      } */}
      <Alert show={show} variant={alertProps.type} onClose={close} dismissible>
        <Alert.Heading>{alertProps.header}</Alert.Heading>
        <p>
          {alertProps.description}
        </p>
      </Alert>
    </>
  );
}


export default Alerts;