import { Button} from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import GeneralModal from "../GeneralModal/GeneralModal";
import { useState } from "react";
import { getWorkflowListCall } from "../../utils/Workflow";
import Alerts from "../Alerts/Alerts";
import { Modal } from "react-bootstrap";
import { CiSettings, CiImport } from "react-icons/ci";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlineFolderOpen } from "react-icons/hi2";
import { useAuth } from "../../context/AuthProvider";
import "./Start.css"

function Start({ panelTabs, setPanelTabs, setKey, setLoadWF, openShowOpTab, showOpTab }) {
    
    const { user } = useAuth();

    const [showModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertProps, setAlertProps] = useState({});
    const [modalType, setModalType] = useState("");
    const [wfList, setWfList] = useState([]);

    const handleClickNewWorkflow = () => {
        setModalType("Workflow");
        setShowModal(true);
    };

    const handleClickLoadWorkflow = async () => {
        const data = await getWorkflowListCall(user.email);
        if ( data.length == 0 ){
            // setModalType("No Workflow to Load");
            setAlertProps({
                type: "danger",
                header: "No Workflows to load",
                description: "No workflows were saved for this user!",
            });
            setShowAlert(true);
        } else {
            setWfList(data);
            setModalType("List");
            setShowModal(true);
        }
    };

    const handleClickNewOperator = () => {
        if(showOpTab){
            // setModalType("New Operator Tab already opened");
            setAlertProps({
                type: "info",
                header: "Tab already open",
                description: "A tab for integrating new operators is already open! Please close it before opening a new one."
            });
            setShowAlert(true);
        } else {
            openShowOpTab();
        }
    }

    return (
        <>
            <style type="text/css">
                {`
                .btn-i4q {
                    background-color: #8064a2;
                    color: white;
                }
                .btn-i4q2 {
                    background-color: white;
                    color: #8064a2;
                    border-color: #8064a2;
                }
                `}
            </style>
            <Stack gap={2} className="col-md-3 mx-auto start-style">
                <div className="group-button">
                    <Button onClick={handleClickNewWorkflow} variant="i4q" className="start-button-style">
                        <p>New Workflow</p>
                        <BsPlusCircle size={30}></BsPlusCircle>
                    </Button>
                    <Button onClick={handleClickLoadWorkflow} variant="i4q2" className="start-button-style">
                        <p>Load Workflow</p>
                        <HiOutlineFolderOpen size={30}></HiOutlineFolderOpen>
                    </Button>
                </div>
                <div className="group-button">
                    <Button onClick={handleClickNewOperator} variant="i4q2" className="start-button-style">
                        <p>Integrate New Operator</p>
                        <CiImport size={30}></CiImport>
                    </Button>
                    <Button variant="i4q" className="start-button-style">
                        <p>Configure Tecnologies</p>
                        <CiSettings size={34}></CiSettings>
                    </Button>
                </div>
            </Stack>
            <Modal show={showAlert} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Alerts alertProps={alertProps} close={() => setShowAlert(false)}/>
            </Modal>
            <GeneralModal 
                setKey={setKey} 
                panelTabs={panelTabs} 
                setPanelTabs={setPanelTabs} 
                type={modalType}
                setModalType={setModalType}
                show={showModal} 
                wfList={wfList}
                setLoadWF={setLoadWF}
                close={() => setShowModal(false)}
            />
        </>
    );
}

export default Start;