import { apiHost, apiPort } from "./Global";


const apiConnectionString = `${apiHost}:${apiPort}`;

const integrateNewOperator = async (opData, file) => {
    const formData = new FormData();
    formData.append('op_name', opData.opName);
    formData.append('op_json', opData.config);
    formData.append('op_file', file);
    var response = await fetch(apiConnectionString + "/integrate_new_operator", {
        'method': 'POST',
        'body' : formData,
        processData: false, 
        contentType: false
    });
    if (response) {
        var data = await response.json();
        // console.log("Data2 ", data);
        return data;
    }
}

export { integrateNewOperator };