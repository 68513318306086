import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginCall } from "../utils/User";
// import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [changeUser, setChangeUser] = useState(false);
    const navigate = useNavigate();

    // call this function when you want to authenticate the user
    const login = async (loginData) => {
        setUser(loginData);
        setChangeUser(!changeUser);
        // navigate("/da", { replace: true });
    };

    useEffect(() => {
        navigate("/da", { replace: true });
    }, [changeUser]);
    // call this function to sign out logged in user
    const logout = () => {
        setUser(null);
        navigate("/", { replace: true });
    };

    const value = useMemo(
        () => ({
            user,
            login,
            logout
        }),
        [user]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContext;
export { AuthProvider };
export { useAuth };