import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { FaPlay, FaSave,  } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import "./TopBarTools.css"


function TopBarTools({saveWorkflow, initWorkflow}) {
    const renderTooltipRun = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Run Workflow on Airflow
        </Tooltip>
    );
    const renderTooltipSave = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Save Workflow
        </Tooltip>
    );
    const renderTooltipDelete = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Delete Workflow
        </Tooltip>
    );
    return (
        
        <div className='TopBarButton vertical-center'>
            <ButtonGroup>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 0 }}
                    overlay={renderTooltipRun}
                >
                    <Button size="sm" onClick={initWorkflow} variant="success"><FaPlay size={15} className="TopBarIcon"></FaPlay></Button>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 0 }}
                    overlay={renderTooltipSave}
                >
                    <Button size="sm" onClick={saveWorkflow} variant="primary"><FaSave size={15} className="TopBarIcon"></FaSave></Button>                   
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 0 }}
                    overlay={renderTooltipDelete}
                >
                    <Button size="sm" variant="danger" disabled={"disabled"}><AiFillDelete size={17} className="TopBarIcon"></AiFillDelete></Button>                    
                </OverlayTrigger>
            </ButtonGroup>
        </div>
    );
}

export default TopBarTools;