import { apiHost, apiPort } from "./Global";

const apiConnectionString = `${apiHost}:${apiPort}`;

async function loginCall(email, password) {
    const formData = new FormData();
    formData.append('login_email', email);
    formData.append('login_password', password);
    const response = await fetch(apiConnectionString + "/login", {
        method: 'POST',
        body: formData,
    });
    return response;
}

async function registerCall(email, password, first_name, last_name){
    const formData = new FormData();
    formData.append('reg_email', email);
    formData.append('reg_password', password);
    formData.append('reg_first_name', first_name);
    formData.append('reg_last_name', last_name);
    const response = await fetch(apiConnectionString + "/register", {
        method: 'POST',
        body: formData,
    });
    return response;
}

async function logoutCall() {
    const response = await fetch(apiConnectionString + "/logout");
    return response;
}

async function forgotPasswordCall(email) {
    const formData = new FormData();
    formData.append('fp_email', email);
    const response = await fetch(apiConnectionString + "/forgot_password", {
        method: 'POST',
        body: formData,
    });
    return response;
}

async function resetPasswordCall(email, password){
    const formData = new FormData();
    formData.append('email', email);
    formData.append('rp_password', password);
    const response = await fetch(apiConnectionString + "/reset_password", {
        method: 'POST',
        body: formData,
    })
}



export {loginCall, registerCall, forgotPasswordCall, resetPasswordCall, logoutCall};